import { toast } from "sonner"

import { getHeaders } from "@/utils/headers"

type BodyInitType = BodyInit | null | undefined

const URI_API = process.env.NEXT_PUBLIC_API_URL

interface FetchOptions extends RequestInit {
  body?: BodyInitType
}

interface ApiResponse<T = any> {
  data: T
  error?: string
  status: number // Adicionando o status aqui
}

const isServer = typeof window === "undefined"

const fetchApi = async <T>(url: string, options: RequestInit = {}): Promise<ApiResponse<T>> => {
  let headers: HeadersInit = new Headers(options.headers)

  if (isServer) {
    const { headers: getHeaders, cookies: getCookies } = await import("next/headers")
    const headersInstance = await getHeaders()
    const cookiesInstance = await getCookies()

    const slug = headersInstance.get("x-institution-subdomain")
    const appToken = headersInstance.get("x-token-institution")
    const token = cookiesInstance.get(`@${slug}:token`)?.value

    if (token) {
      headers.append("Authorization", `Bearer ${token}`)
    }
    if (appToken) {
      headers.append("Token", appToken)
    }
  } else {
    const { appToken, token } = await getHeaders()

    if (token) {
      headers.append("Authorization", `Bearer ${token}`)
    }
    if (appToken) {
      headers.append("Token", appToken)
    }
  }

  if (options.body instanceof FormData) {
    for (let [key, value] of options.body.entries()) {
      if (value === "" || value === undefined) {
        options.body.delete(key)
      }
    }
  } else if (options.body && typeof options.body === "object") {
    // content-type application/json
    headers.set("Content-Type", "application/json")
    const bodyObject = options.body as { [key: string]: any }
    Object.keys(options.body).forEach((key) => {
      if (bodyObject[key] === "" || bodyObject[key] === undefined) {
        bodyObject[key] = null
      }
    })
    options.body = JSON.stringify(options.body)
  }
  const response = await fetch(`${URI_API}${url}`, {
    ...options,
    headers,
  })

  if (!response.ok) {
    const errorData = (await response.json()) as { message: string; errors: { message: string }[] }

    if (errorData.errors?.length > 0 && !isServer) {
      errorData.errors.forEach((error) => {
        toast.error(error.message)
      })
    }

    if(response.status === 400 && errorData.message && !isServer) {
      toast.error(errorData.message)
    }

    throw new Error(errorData.message || "Request failed")
  }

  if (response.status === 204) {
    return { data: null, status: response.status } as ApiResponse<T>
  }
  // if (response.status === 500) {
  //   clearData()
  //   redirect("/")
  // }

  // Verifica o tipo de dado retornado
  let data: any

  if (response.headers.get("Content-Type")?.includes("application/json")) {
    data = await response.json()
  } else {
    data = await response.text()
  }

  // Retornando também o status da resposta
  return { data, status: response.status } as ApiResponse<T>
}

const api = {
  get: <T>(url: string, options: FetchOptions = {}) => fetchApi<T>(url, { ...options, method: "GET" }),
  post: <T>(url: string, body?: any, options: FetchOptions = {}) =>
    fetchApi<T>(url, { ...options, method: "POST", body }),
  put: <T>(url: string, body: any, options: FetchOptions = {}) => fetchApi<T>(url, { ...options, method: "PUT", body }),
  delete: <T>(url: string, options: FetchOptions = {}) => fetchApi<T>(url, { ...options, method: "DELETE" }),
  patch: <T>(url: string, body: any, options: FetchOptions = {}) =>
    fetchApi<T>(url, { ...options, method: "PATCH", body }),
}

export default api
