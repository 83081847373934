import { create } from "zustand"

import { clearCookies } from "@/app/actions"
import { IUser } from "@/interfaces/user"
import api from "@/services/api-server"
import { PermissionsEnum } from "@/utils/permissions-list"
import { IRoute } from "@/utils/routes"

const items = Object.keys(PermissionsEnum) as Array<keyof typeof PermissionsEnum>
export type PermissionsList = (typeof items)[number]

interface IUserProps {
  user: IUser | null
  setUser: (value: IUser | null) => void
  signOut: () => void
  menusUser: IRoute[]
  setMenusUser: (value: IRoute[]) => void
  permissions: string[]
  setPermissions: (value: string[]) => void
  hasPermission: (permission: PermissionsList) => boolean
  setOpenLogin: (value: boolean) => void
  openLogin: boolean
  logoutUser: () => Promise<void>
}

const useAuth = create<IUserProps>((set, get) => {
  const isClient = typeof window !== "undefined"

  const domain = isClient ? window.location.host.replace(`.${process.env.NEXT_PUBLIC_ROOT_DOMAIN}`, "") : ""
  const userLocalStoraged = isClient ? (localStorage.getItem(`@${domain}:user`) as string) : null
  const user = isClient ? (userLocalStoraged ? (JSON.parse(userLocalStoraged) as IUser) : null) : null

  return {
    user: user,
    setUser: (value: IUser | null) => set({ user: value }),
    signOut: async () => {
      clearCookies()
      localStorage.clear()
      set({ user: null })
      window.location.href = "/login"
    },
    logoutUser: async () => {
      clearCookies()
      localStorage.removeItem(`@${domain}:user`)
      await api.delete("/sessions")
      set({ user: null })
    },
    menusUser: [],
    setMenusUser: (value: IRoute[]) => set({ menusUser: value }),
    permissions: [],
    setPermissions: (value: string[]) => {
      const user = get().user
      if (user?.roles?.some((item) => item.name === "Super Administrador")) {
        value.push("super-admin")
      }
      set({ permissions: value })
    },
    hasPermission: (permission: PermissionsList): boolean => {
      return get().permissions.includes(PermissionsEnum[permission])
    },
    setOpenLogin: (value: boolean) => set({ openLogin: value }),
    openLogin: false,
  }
})

export default useAuth
