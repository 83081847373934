export enum PermissionsEnum {
  "Listar Colaboradores" = "user-list",
  "Adicionar Colaborador" = "user-store",
  "Editar Colaborador" = "user-update",
  "Excluir Colaborador" = "user-destroy",
  "Listar Regras de Acessos" = "role-list",
  "Adicionar Regra de Acesso" = "role-store",
  "Editar Regra de Acesso" = "role-update",
  "Excluir Regra de Acesso" = "role-destroy",
  "Listar Grupos de Produtos" = "group-product-list",
  "Adicionar Grupo de Produto" = "group-product-store",
  "Editar Grupo de Produto" = "group-product-update",
  "Excluir Grupo de Produto" = "group-product-destroy",
  "Listar Categorias" = "category-list",
  "Adicionar Categoria" = "category-store",
  "Editar Categoria" = "category-update",
  "Excluir Categoria" = "category-destroy",
  "Listar Recursos" = "feature-list",
  "Adicionar Recurso" = "feature-store",
  "Editar Recurso" = "feature-update",
  "Excluir Recurso" = "feature-destroy",
  "Listar Produtos" = "product-list",
  "Adicionar Produto" = "product-store",
  "Editar Produto" = "product-update",
  "Excluir Produto" = "product-destroy",
  "Listar Banners" = "banner-list",
  "Adicionar Banner" = "banner-store",
  "Editar Banner" = "banner-update",
  "Excluir Banner" = "banner-destroy",
  "Listar Páginas" = "page-list",
  "Adicionar Página" = "page-store",
  "Editar Página" = "page-update",
  "Excluir Página" = "page-destroy",
  "Listar Polos" = "pole-list",
  "Adicionar Polo" = "pole-store",
  "Editar Polo" = "pole-update",
  "Excluir Polo" = "pole-destroy",
  "Listar Pedidos" = "order-list",
  "Adicionar Pedido" = "order-store",
  "Editar Pedido" = "order-update",
  "Excluir Pedido" = "order-destroy",
  "Listar Cupons" = "coupon-list",
  "Adicionar Cupom" = "coupon-store",
  "Editar Cupom" = "coupon-update",
  "Excluir Cupom" = "coupon-destroy",
  "Importar Dados (genéricos) via CSV" = "import",
  "Importar Estudantes via CSV" = "import-student",
  "Importar Produtos via CSV" = "import-product",
  "Importar Usuários via CSV" = "import-user",
  "Listar FAQ's do Site" = "faq-list",
  "Adicionar FAQ do Site" = "faq-store",
  "Editar FAQ do Site" = "faq-update",
  "Excluir FAQ do Site" = "faq-destroy",
  "Listar Depoimentos" = "testimony-list",
  "Adicionar Depoimento" = "testimony-store",
  "Editar Depoimento" = "testimony-update",
  "Excluir Depoimento" = "testimony-destroy",
  "Listar Contatos" = "contact-list",
  "Visualizar Contato" = "contact-store",
  "Listar FAQ's de Produtos" = "productfaq-list",
  "Adicionar FAQ de Produtos" = "productfaq-store",
  "Editar FAQ de Produtos" = "productfaq-update",
  "Excluir FAQ de Produtos" = "productfaq-destroy",
  "Listar Estudantes" = "student-list",
  "Adicionar Estudante" = "student-store",
  "Editar Estudante" = "student-update",
  "Excluir Estudante" = "student-destroy",
  "Listar Interesses em Produtos" = "list-interest",
  "Visualizar Interesses em Produtos" = "view-interest",
  "Editar Instituição" = "institution-update",
  "Adicionar Instituição" = "institution-store",
  "Listar Treinamentos" = "training-list",
  "Adicionar Treinamento" = "training-store",
  "Editar Treinamento" = "training-update",
  "Excluir Treinamento" = "training-destroy",
  "Listar Grupos de Livros da Biblioteca" = "library-group-list",
  "Adicionar Grupo de Livros da Biblioteca" = "library-group-store",
  "Editar Grupo de Livros da Biblioteca" = "library-group-update",
  "Excluir Grupo de Livros da Biblioteca" = "library-group-destroy",
  "Listar Livros da Biblioteca" = "library-books-list",
  "Adicionar Livro da Biblioteca" = "library-books-store",
  "Editar Livro da Biblioteca" = "library-books-update",
  "Excluir Livro da Biblioteca" = "library-books-destroy",
  "Listar Professores" = "teacher-list",
  "Adicionar Professor" = "teacher-store",
  "Editar Professor" = "teacher-update",
  "Excluir Professor" = "teacher-destroy",
  "Listar Disciplinas" = "discipline-list",
  "Adicionar Disciplina" = "discipline-store",
  "Editar Disciplina" = "discipline-update",
  "Excluir Disciplina" = "discipline-destroy",
  "Importar Certificados via CSV" = "import-certificate",
  "Listar Certificados" = "certificate-list",
  "Adicionar Certificado" = "certificate-store",
  "Editar Certificado" = "certificate-update",
  "Excluir Certificado" = "certificate-destroy",
  "Listar Recebedores" = "recipient-list",
  "Adicionar Recebedor" = "recipient-store",
  "Editar Recebedor" = "recipient-update",
  "Excluir Recebedor" = "recipient-destroy",
  "Listar Áreas de Conhecimentos" = "knowledgeArea-list",
  "Adicionar Área de Conhecimento" = "knowledgeArea-store",
  "Editar Área de Conhecimento" = "knowledgeArea-update",
  "Excluir Área de Conhecimento" = "knowledgeArea-destroy",
  "Listar NFe's" = "nfe-list",
  "Adicionar NFe" = "nfe-store",
  "Editar NFe" = "nfe-update",
  "Excluir NFe" = "nfe-destroy",
  "Listar Documentos de Estudantes" = "document-list",
  "Adicionar Documento de Estudante" = "document-store",
  "Editar Documento de Estudante" = "document-update",
  "Excluir Documento de Estudante" = "document-destroy",
  "Aprovar Documento de Estudante" = "document-approve",
  "Listar Tipos de Documentos" = "typeDocument-list",
  "Adicionar Tipo de Documento" = "typeDocument-store",
  "Editar Tipo de Documento" = "typeDocument-update",
  "Excluir Tipo de Documento" = "typeDocument-destroy",
  "Listar Cursos" = "course-list",
  "Adicionar Curso" = "course-store",
  "Editar Curso" = "course-update",
  "Excluir Curso" = "course-destroy",
  "Alterar Senha de Colaborador" = "user-password",
  "Alterar Senha de Estudante" = "student-password",
  "Importar NFe via ZIP (XML)" = "import-nfe",
  "Listar Mídias" = "media-list",
  "Adicionar Mídia" = "media-store",
  "Excluir Mídia" = "media-destroy",
  "Listar Template de Documentos e Certificados" = "template-document-list",
  "Adicionar Template de Documentos e Certificados" = "template-document-store",
  "Editar Template de Documentos e Certificados" = "template-document-update",
  "Excluir Template de Documentos e Certificados" = "template-document-destroy",
  "Listar Turmas" = "class-list",
  "Adicionar Turma" = "class-store",
  "Editar Turma" = "class-update",
  "Excluir Turma" = "class-destroy",
  "Listar Matrículas" = "enrollment-list",
  "Adicionar Matrícula" = "enrollment-store",
  "Editar Matrícula" = "enrollment-update",
  "Excluir Matrícula" = "enrollment-destroy",
  "Ver Dados da Instituição" = "institution-show",
  "Ver Documentos de Estudantes" = "student-document-show",
  "Baixar Certificado SEM Assinatura" = "certificate-download-without-signature",
  "Super Administrador" = "super-admin",
}

const items = (Object.keys(PermissionsEnum) as Array<keyof typeof PermissionsEnum>)[0]

export type PermissionsList = typeof items
