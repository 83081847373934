import useAuth from "@/store/useAuth"

import { PermissionsEnum } from "./permissions-list"

const items = Object.keys(PermissionsEnum) as Array<keyof typeof PermissionsEnum>
export type PermissionsList = (typeof items)[number]

const hasPermission = (permission: PermissionsList): boolean => {
  return useAuth.getState().permissions.includes(PermissionsEnum[permission])
}

const generateYears = (): { label: string; value: string | number }[] => {
  const years: { label: string; value: string | number }[] = []
  for (let i = new Date().getFullYear(); i >= 1900; i--) {
    years.push({ label: i.toString(), value: String(i) })
  }
  return [...years]
}

const races = [
  {
    value: "branca",
    label: "Branca",
  },
  {
    value: "preta",
    label: "Preta",
  },
  {
    value: "parda",
    label: "Parda",
  },
  {
    value: "amarela",
    label: "Amarela",
  },
  {
    value: "indígena",
    label: "Indígena",
  },
]

const uuidv4 = (): string => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const isDev = process.env.NODE_ENV === "development"

export { generateYears, hasPermission, isDev, races, uuidv4 }
