import * as React from "react"
import { useFormContext } from "react-hook-form"

import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/lib/utils"
import { hasPermission, type PermissionsList } from "@/utils/common"

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-text-primary shadow hover:opacity-90",
        destructive: "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
        outline: "border border-primary bg-white shadow-sm  hover:bg-white/80 hover:text-primary",
        secondary: "border border-gray-300 bg-secondary text-text-secondary shadow-sm hover:opacity-90",
        ghost: "text-primary hover:bg-accent hover:text-primary",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-9 px-4 py-2",
        sm: "h-8 rounded-md px-3 text-xs",
        lg: "h-10 rounded-md px-8",
        icon: "size-9",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

const isServer = typeof window === "undefined"

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  permission?: PermissionsList
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, permission, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(
          buttonVariants({
            variant,
            size,
            className: cn(className, permission ? (hasPermission(permission) ? "" : "hidden") : ""),
          })
        )}
        ref={ref}
        {...props}
        disabled={permission ? (hasPermission(permission) ? false : true) : props.disabled}
      />
    )
  }
)
Button.displayName = "Button"

// Button-Form
const ButtonForm = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, permission, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    const {
      formState: { isSubmitting },
    } = useFormContext()
    return (
      <Comp
        className={cn(
          buttonVariants({
            variant,
            size,
            className: cn(className, permission ? (hasPermission(permission) ? "" : "hidden") : ""),
          })
        )}
        ref={ref}
        {...props}
        disabled={permission ? (hasPermission(permission) ? false : true) : props.disabled || isSubmitting}
      />
    )
  }
)
Button.displayName = "Button"

export { Button, ButtonForm, buttonVariants }
