"use client"

interface Headers {
  domain?: string
  appToken?: string
  token?: string
}

// Função para buscar headers
export async function getHeaders(): Promise<Headers> {
  try {
    const response = await fetch("/api/headers")
    const data = await response.json()
    return data
  } catch (error) {
    console.error("Erro ao buscar headers:", error)
    return {}
  }
}
